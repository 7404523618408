import * as React from "react";
import { useCarousel } from "@/app/_components/Carousel/hooks/useCarousel";
import { cn } from "@/lib/utils";

export const PageControlController = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<any>
>(({ className, size, item, ...props }, ref) => {
  const { scrollTo, scrollSnaps, selectedIndex } = useCarousel();

  const OnClickPaginationItem =
    (id: number) => (event: React.MouseEvent<HTMLElement>) => {
      scrollTo(id);
    };
  if (scrollSnaps.length === 1) {
    return <div className="flex grow gap-1 laptop:gap-2" />;
  }
  return (
    <>
      <div
        className={cn(
          "flex min-h-[2rem] w-full items-center justify-center gap-1 tablet:hidden laptop:gap-2",
          className,
        )}
      >
        {Array.from(Array(size).keys()).map((id: number, index: number) =>
          React.cloneElement(item, {
            key: id,
            onClick: OnClickPaginationItem(id),
            isActive: id === selectedIndex,
            index,
          }),
        )}
      </div>
      <div
        className={cn(
          "hidden min-h-[2rem] w-full items-center justify-center gap-3.5 tablet:flex laptop:gap-2",
          className,
        )}
      >
        {scrollSnaps.map((id: number, index: number) =>
          React.cloneElement(item, {
            key: id,
            onClick: OnClickPaginationItem(index),
            isActive: index === selectedIndex,
            index,
          }),
        )}
      </div>
    </>
  );
});

PageControlController.displayName = "PageControlController";
