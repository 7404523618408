import add from "date-fns/add";
import format from "date-fns/format";

export const formatDateToGerman = (dateObject: Date): string => {
  // DateTimeFormat.format with specific options
  const f = new Intl.DateTimeFormat("de", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "Europe/Berlin",
  });
  return f.format(dateObject);
};

/**
 *
 * @param daysToInstallation is the number of days until the installation
 * @returns a string with the earliest installation date in the format "dd.mm.yyyy"
 * and "keine Angabe" if daysToInstallation is null
 */
export const getInstallationDateString = (daysToInstallation: number) => {
  const earliestInstallationDate =
    daysToInstallation != null && add(new Date(), { days: daysToInstallation });

  return earliestInstallationDate
    ? earliestInstallationDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : "keine Angabe";
};

export function formatDate(date: Date, formatString: string): string {
  return format(date, formatString);
}
