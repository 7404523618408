"use client";

import React, { forwardRef } from "react";

import {
  Button as ReactAriaButton,
  ButtonProps as ReactAriaButtonProps,
} from "react-aria-components";

import useButton, { ButtonProps } from "./useButton";
import ButtonContent from "./ButtonContent";

export interface ButtonComponentProps
  extends ButtonProps,
    Omit<ReactAriaButtonProps, "children" | "className"> {
  children: React.ReactNode;
  badge?: number;
}

const Button = forwardRef<HTMLButtonElement, ButtonComponentProps>(
  ({ children = "", type = "button", ...props }, ref) => {
    const { ...buttonProps } = useButton(props);

    return (
      <ReactAriaButton ref={ref} type={type} {...buttonProps}>
        <ButtonContent {...props}>{children}</ButtonContent>
      </ReactAriaButton>
    );
  },
);

Button.displayName = "Button";

export default Button;
