import * as React from "react";
import { CarouselContext } from "@/app/_components/Carousel/Carousel";

export function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
}
