"use client";

import Image from "next/image";
import { useCallback, useEffect, useRef } from "react";

import Icon from "@/app/_components/icon";
import { cn } from "@/lib/utils";
import MockLaptop from "./_media/laptop-Mock.png";

import { type StepData } from "./howItWorks";

function Steps({
  stepData,
  className,
}: {
  stepData: StepData[];
  className?: string;
}) {
  const iconRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleIntersection = useCallback(
    ([entry]: IntersectionObserverEntry[], index: number) => {
      if (!entry.isIntersecting) return;

      const icons = iconRefs.current;
      icons.forEach((iconRef, i) => {
        const circle = iconRef?.querySelector(".icon-circle");
        const bgElement = circle?.querySelector("div");
        const connectorLine = iconRef?.querySelector(".connector-line");

        if (i <= index) {
          // Active state
          circle?.classList.remove("border-2", "border-text-400");
          bgElement?.classList.replace("scale-0", "scale-100");
          connectorLine?.classList.replace("bg-text-400", "bg-primary-200");
        } else {
          // Inactive state
          circle?.classList.add("border-2", "border-text-400");
          bgElement?.classList.replace("scale-100", "scale-0");
          connectorLine?.classList.replace("bg-primary-200", "bg-text-400");
        }
      });
    },
    [],
  );

  useEffect(() => {
    const observers = iconRefs.current.map((ref, index) => {
      const observer = new IntersectionObserver(
        entries => handleIntersection(entries, index),
        {
          threshold: 0,
          rootMargin: "-40% 0px -60% 0px", // triggers at 40% from the top
        },
      );

      if (ref) observer.observe(ref);
      return observer;
    });

    return () => observers.forEach(observer => observer?.disconnect());
  }, [handleIntersection]);
  return (
    <div className={cn("flex gap-11 desktop:gap-16 max:gap-20", className)}>
      <div className="flex-1">
        <Image src={MockLaptop} alt="example" height={500} />
      </div>
      <div className="flex flex-1 flex-col justify-center">
        {stepData.map((item, index) => (
          <div key={item.title} className="flex gap-4 desktop:gap-6">
            <div
              className="relative flex w-fit flex-col items-center justify-center"
              ref={el => {
                if (el) iconRefs.current[index] = el;
              }}
            >
              {/* connector line */}
              {index !== 0 && (
                <div className="connector-line left-5 h-10 w-0.5 bg-text-400 desktop:h-12" />
              )}
              <div className="flex items-center ">
                <div
                  className={cn(
                    "icon-circle relative flex size-10 items-center justify-center overflow-hidden rounded-full desktop:size-12",
                    { "border-2 border-text-400": index > 0 },
                    { "bg-primary-200": index === 0 },
                  )}
                >
                  {/* background element for animation */}
                  <div className="absolute inset-0 origin-center scale-0 transform rounded-full bg-primary-200 transition-transform duration-300" />
                  <Icon
                    className="relative z-10 size-5 text-tertiary-500 desktop:size-6"
                    name={item.iconName}
                  />
                </div>
              </div>
            </div>
            <span className="h-10 content-center self-end text-base font-semibold">
              {item.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Steps;
