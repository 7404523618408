interface PageControlElementProps {
  isActive: boolean;
  onClick?: () => void;
}

const PageControlElement = ({
  isActive,
  onClick = () => {
    throw new Error("You forgot to add the Click Listener");
  },
}: PageControlElementProps) => (
  <button
    onClick={onClick}
    className={`
              inline-flex flex-col items-start justify-center py-3
              laptop:py-3.5
              `}
  >
    <div
      className={` group inline-flex flex-col items-start justify-center p-0.5


      ${
        isActive
          ? "laptop:hover:bg-tertiary-500 laptop:hover:bg-opacity-[0.24]"
          : "laptop:hover:bg-text-300 laptop:hover:bg-opacity-[0.32]"
      }`}
    >
      <div
        className={` focus-visible:radius-none w-full p-px
                     focus-visible:border focus-visible:border-blue focus-visible:outline-none
                     laptop:active:bg-tertiary-500 laptop:active:bg-opacity-40
              `}
      >
        <div
          className={`h-1 w-6 self-stretch laptop:w-10 laptop:group-hover:bg-opacity-[1] ${
            isActive ? "bg-tertiary-500" : "bg-text-300"
          }`}
        />
      </div>
    </div>
  </button>
);
export default PageControlElement;
