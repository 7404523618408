"use client";

import { trpc } from "@/trpc/client";
import { Tag } from "@/app/_components/tags";
import { formatDate } from "@/app/_helpers/formatDate";
import isToday from "date-fns/isToday";

export default function AnnouncementBar() {
  const { data } = trpc.pvLeadline.getLeadStatistics.useQuery();

  const totalOfferQueries =
    data?.total_offer_queries !== undefined
      ? new Intl.NumberFormat("de-DE").format(data.total_offer_queries)
      : undefined;

  const date = data?.latest_offer_query_timestamp
    ? formatDate(
        new Date(data.latest_offer_query_timestamp),
        "dd.MM.yyyy 'um' HH:mm",
      )
    : undefined;

  const wasLastRequestToday = data
    ? isToday(new Date(data?.latest_offer_query_timestamp))
    : false;

  return (
    <div className="flex items-center justify-center gap-8 bg-primary-100 px-4 py-0.5 laptop:gap-20 desktop:gap-28">
      <Tag
        className="bg-transparent"
        icon="knowledge"
        iconClassName="text-primary-600"
      >
        Ohne E-Mail Anmeldung
      </Tag>
      {date && (
        <Tag
          className="hidden bg-transparent laptop:flex"
          icon="lead"
          iconClassName="text-primary-600"
        >
          Letzte Anfrage {wasLastRequestToday ? "heute" : "gestern"} {date}
        </Tag>
      )}
      <Tag
        className="hidden bg-transparent tablet:flex"
        icon="check-circle"
        iconClassName="text-primary-600"
      >
        {totalOfferQueries} Anfragen gestellt
      </Tag>
    </div>
  );
}
