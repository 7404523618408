import * as React from "react";
import { cn } from "@/lib/utils";
import PageControlElement from "@/app/_components/Carousel/PageControl/PageControlElement";
import { PageControlController } from "@/app/_components/Carousel/PageControlController/PageControlController";
import { useCarousel } from "../hooks/useCarousel";

interface CarouselContentProps extends React.HTMLAttributes<HTMLDivElement> {
  pagination:
    | {
        size: number;
        active?: number;
      }
    | undefined;
  outerClassName?: string;
  withPagination?: boolean;
}

export const CarouselContent = React.forwardRef<
  HTMLDivElement,
  CarouselContentProps
>(
  (
    { className, pagination, outerClassName, withPagination = true, ...props },
    ref,
  ) => {
    const { carouselRef, orientation } = useCarousel();
    return (
      <div
        ref={carouselRef}
        className={cn(
          "-ml-8 -mr-4 flex flex-col overflow-hidden",
          outerClassName,
        )}
      >
        <div
          ref={ref}
          className={cn(
            "flex gap-5 first:ml-8 last:mr-8 tablet:gap-8",
            orientation === "horizontal" ? "" : " flex-col",
            className,
          )}
          {...props}
        />
        {withPagination && pagination?.size && pagination.size !== 1 ? (
          <div className="flex min-h-[2.1rem] flex-row justify-center">
            <PageControlController
              size={
                pagination?.size && pagination.size > 0 ? pagination.size : 1
              }
              item={<PageControlElement isActive />}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  },
);
CarouselContent.displayName = "CarouselContent";
