"use client";

import { cn } from "@/lib/utils";

import Image from "next/image";
import tuvBadge from "@/public/tuev-webcheck-badge.png";
import heyDataBadge from "@/app/_media/Trust_Badges_HeyData.png";
import BestPrice from "@/app/_media/svg/best-price.svg";

const SocialProof: React.FC<{ className?: string }> = ({ className }) => (
  <div className={cn("flex flex-col gap-4", className)}>
    <div className="lp-social-proof flex items-center gap-5 tablet:gap-8 laptop:gap-10">
      <Image
        className="size-12 tablet:size-16"
        width={120}
        height={120}
        src={tuvBadge}
        alt="TÜV Saarland Webcheck"
        title="TÜV Webcheck"
      />
      <Image
        className="size-12 tablet:size-16"
        width={120}
        height={120}
        src={heyDataBadge}
        alt="TÜV Saarland Webcheck"
        title="Qualitätssiegel Datenschutz"
      />
      <div title="Nirgendwo günstiger Garantie">
        <BestPrice className="h-12 tablet:h-16" />
      </div>
      <div className="hidden flex-grow tablet:flex laptop:hidden desktop:flex max:hidden">
        {/* Google Reviews */}
        <div className="reviewforest-app-daeee7b0-649f-46d8-aecf-0f3f9d4adb89 " />
      </div>
    </div>
    <div className="hidden flex-grow laptop:flex desktop:hidden max:flex">
      {/* Google Reviews */}
      <div className="reviewforest-app-daeee7b0-649f-46d8-aecf-0f3f9d4adb89" />
    </div>
  </div>
);

export default SocialProof;
