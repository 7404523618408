import { cn } from "@/lib/utils";

import Icon from "@/app/_components/icon";

interface ChecklistItemProps {
  children: React.ReactNode;
  variant?: "small" | "large";
  className?: string;
}

const ChecklistItem: React.FC<ChecklistItemProps> = ({
  children,
  variant,
  className = undefined,
}) => (
  <div
    className={cn(
      "flex items-start gap-x-2",
      {
        "text-base font-semibold": variant === "large",
        "text-sm font-normal": variant === "small",
        "text-sm font-semibold": !variant,
      },
      className,
    )}
  >
    <Icon
      name="check"
      className={cn(
        "mt-0.5 size-4 flex-none text-primary-500 cosma:text-text tablet:size-5",
        {
          "mt-[1px] size-4": variant === "small",
          "size-5": variant === "large",
        },
      )}
    />
    {children}
  </div>
);

export default ChecklistItem;
