import classNames from "classnames";

interface TeaserBackgroundProps {
  className?: string;
  children?: React.ReactNode;
}

const TeaserBackground: React.FC<TeaserBackgroundProps> = ({
  className,
  children,
}) => (
  <div className={classNames("absolute h-full w-full", className)}>
    <div className="relative h-full w-full">{children}</div>
  </div>
);

export default TeaserBackground;
