import { cn } from "@/lib/utils";

interface PageControlElementProps {
  isActive: boolean;
  onClick?: () => void;
  index?: number;
}

const PageControlElement2 = ({
  isActive,
  index,
  onClick,
}: PageControlElementProps) => (
  <div
    className={cn("flex min-w-6 grow flex-col self-start", {
      "w-1/2": isActive,
    })}
  >
    <button
      onClick={onClick}
      className={cn("flex self-stretch pt-5 laptop:w-10 laptop:pt-4")}
    >
      <div
        className={cn(
          "group inline-flex w-full flex-col p-0.5 laptop:hover:bg-text-300 laptop:hover:bg-opacity-[0.32]",
          {
            "laptop:hover:bg-tertiary-500 laptop:hover:bg-opacity-[0.24]":
              isActive,
          },
        )}
      >
        <div
          className={` focus-visible:radius-none w-full p-px
          focus-visible:border focus-visible:border-blue focus-visible:outline-none
          laptop:active:bg-tertiary-500 laptop:active:bg-opacity-40
          `}
        >
          <div
            className={`h-1 w-full self-stretch laptop:group-hover:bg-opacity-[1] ${
              isActive ? "bg-tertiary-500" : "bg-text-300"
            }`}
          />
        </div>
      </div>
    </button>
    {typeof index === "number" && (
      <span className="text-tertiary-500">
        {isActive ? `0${index + 1}` : ""}
      </span>
    )}
  </div>
);
export default PageControlElement2;
