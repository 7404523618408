import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export default function Section({
  children,
  className = undefined,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <section
      className={twMerge(
        "relative z-0 px-4 tablet:px-5 laptop:px-8 max:px-[7.5rem]",
        className,
      )}
    >
      {children}
    </section>
  );
}
