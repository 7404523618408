import { cn } from "@/lib/utils";

interface FeatureTileProps {
  children: React.ReactNode;
  className?: string;
}

export function FeatureTile({ children, className }: FeatureTileProps) {
  return (
    <div
      className={cn(
        "flex items-center justify-between gap-x-3 bg-text px-3 pt-5 tablet:pt-3",
        className,
      )}
    >
      {children}
    </div>
  );
}

export function FeatureTileStep({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={cn("text-3xl font-black text-primary-200", className)}>
      {children}
    </div>
  );
}

export function FeatureTileTitle({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "hyphens-auto text-xl font-black text-background",
        className,
      )}
    >
      {children}
    </div>
  );
}

export function FeatureTileTextContent({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "flex w-1/2 grow flex-col items-baseline gap-x-4 gap-y-0.5 self-stretch tablet:w-2/3",
        className,
      )}
    >
      {children}
    </div>
  );
}
